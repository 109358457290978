window.addEventListener('load', function () {
  setTimeout(function () {
    const loader = document.getElementById('loader');
    loader.style.display = 'none';
  }, 100);
});

document.addEventListener("DOMContentLoaded", function() {
  let navbar = document.querySelector('.autohide');

  if (navbar) {
    window.addEventListener('scroll', function() {
      let yPos = window.scrollY;

      if (yPos < 32) {
        navbar.classList.remove('scrolled-down');
        navbar.classList.add('scrolled-up');
      } else {
        navbar.classList.remove('scrolled-up');
        navbar.classList.add('scrolled-down');
      }
    });
  }
});
